var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"double-trestle-container",style:({
    opacity: _vm.selectedTrestle ? (_vm.selectedTrestle === _vm.trestle ? 1 : 0.3) : 1,
  })},[_c('div',{staticClass:"pa-1 flex-1",staticStyle:{"border-left":"dashed 1px #6b6b6b","border-right":"dashed 1px #6b6b6b"}},[_vm._l((_vm.trestle.positions.slice(0, 5)),function(position,elementIndex){return _c('v-tooltip',{key:`trestle-north-${elementIndex}`,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"flex-1 mb-1 d-flex align-center px-1",style:({
            border: position.isEmpty
              ? 'dotted 1px #6b6b6b'
              : 'solid 1px #6b6b6b',
            background: position.isEmpty
              ? 'transparent'
              : _vm.typeColorDictionary[position.element.module_type],
            opacity:
              _vm.selectedModule !== null
                ? _vm.selectedModule === position?.element?.module_id
                  ? 1
                  : 0.3
                : 1,
          })},on),[_c('div',{staticClass:"flex-1 d-flex justify-start"},[_vm._v(" "+_vm._s(elementIndex + 1)+" ")]),_c('div',{staticClass:"flex-1 d-flex justify-end"},[_vm._v(" "+_vm._s(!position.isEmpty ? position.element.element_type ? position.element.element_type : '-' : '')+" ")])])]}}],null,true)},[(!position.isEmpty)?_c('span',[_vm._v(_vm._s(position.element.element_id))]):_vm._e()])}),_c('div',{staticClass:"my-2 double-trestle-separator"}),_vm._l((_vm.trestle.positions.slice(5, 10)),function(position,elementIndex){return _c('v-tooltip',{key:`trestle-north-${elementIndex}`,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"flex-1 mb-1 d-flex align-center px-1",style:({
            border: position.isEmpty
              ? 'dotted 1px #6b6b6b'
              : 'solid 1px #6b6b6b',
            background: position.isEmpty
              ? 'transparent'
              : _vm.typeColorDictionary[position.element.module_type],
            opacity:
              _vm.selectedModule !== null
                ? _vm.selectedModule === position?.element?.module_id
                  ? 1
                  : 0.3
                : 1,
          })},on),[_c('div',{staticClass:"flex-1 d-flex justify-start"},[_vm._v(" "+_vm._s(elementIndex + 1)+" ")]),_c('div',{staticClass:"flex-1 d-flex justify-end"},[_vm._v(" "+_vm._s(!position.isEmpty ? position.element.element_type ? position.element.element_type : '-' : '')+" ")])])]}}],null,true)},[(!position.isEmpty)?_c('span',[_vm._v(_vm._s(position.element.element_id))]):_vm._e()])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }