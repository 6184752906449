import moment from 'moment/moment';

const defaultLength = 6000;

export function generateAutomatedVirtualTrestlesByPosition(modules, positions) {
  let trestles = [];

  let trestle = Object.assign(
    {},
    {
      positions: [
        {
          elements: [],
          length: defaultLength,
        },
        {
          elements: [],
          length: defaultLength,
        },
        {
          elements: [],
          length: defaultLength,
        },
        {
          elements: [],
          length: defaultLength,
        },
        {
          elements: [],
          length: defaultLength,
        },
      ],
    }
  );

  let modulesWithElements = modules.filter(
    (module) => module.elements.length > 0
  );

  modulesWithElements.forEach((module, index) => {
    let positionIndex = 0;

    let elements = module.elements.filter(
      (element) =>
        positions.includes(element.element_position.charAt(0)) &&
        element.element_position.charAt(1) === '.'
    );

    elements.forEach((element) => {
      if (element.length === null) {
        element.length = defaultLength;
      }
      element.onTrestle = false;
      while (!element.onTrestle) {
        if (trestle.positions[positionIndex].length - element.length >= 0) {
          trestle.positions[positionIndex].elements.push(element);
          trestle.positions[positionIndex].length -= element.length;
          element.onTrestle = true;
        } else {
          positionIndex++;
          if (positionIndex === 5) {
            trestles.push(trestle);
            trestle = Object.assign(
              {},
              {
                positions: [
                  {
                    elements: [],
                    length: defaultLength,
                  },
                  {
                    elements: [],
                    length: defaultLength,
                  },
                  {
                    elements: [],
                    length: defaultLength,
                  },
                  {
                    elements: [],
                    length: defaultLength,
                  },
                  {
                    elements: [],
                    length: defaultLength,
                  },
                ],
              }
            );
            positionIndex = 0;
          }
        }
      }
    });
  });
  for (let i = 0; i < trestle.positions.length; i++) {
    if (trestle.positions[i].elements.length === 0) {
      trestle.positions.splice(i, 1);
      trestle.positions.unshift({
        elements: [],
        length: defaultLength,
      });
    }
  }

  trestles.push(trestle);

  return trestles;
}

export function generateManualVirtualTrestles(modules, lane, locations) {
  let trestles = [];

  let trestle = Object.assign(
    {},
    {
      positions: [
        {
          elements: [],
          length: defaultLength,
        },
        {
          elements: [],
          length: defaultLength,
        },
        {
          elements: [],
          length: defaultLength,
        },
        {
          elements: [],
          length: defaultLength,
        },
        {
          elements: [],
          length: defaultLength,
        },
        {
          elements: [],
          length: defaultLength,
        },
        {
          elements: [],
          length: defaultLength,
        },
        {
          elements: [],
          length: defaultLength,
        },
        {
          elements: [],
          length: defaultLength,
        },
        {
          elements: [],
          length: defaultLength,
        },
      ],
    }
  );

  console.log(modules);

  let modulesWithElements = modules
    .filter(
      (module) =>
        module.elements.length > 0 &&
        locations
          .filter((location) => location.assembly_lane === lane.type)
          .some((location) => location.id === module.assembly_location)
    )
    .sort(
      (a, b) =>
        moment(a.planned_start).isAfter(moment(b.planned_start)) ||
        locations.find((location) => location.id === a.assembly_location)
          .assembly_location -
          locations.find((location) => location.id === b.assembly_location)
            .assembly_location
    )
    .reverse();

  console.log(modulesWithElements);

  modulesWithElements.forEach((module, index) => {
    let positionIndex = 0;
    if (module.elements.length <= 10 - positionIndex) {
      module.elements.forEach((element) => {
        if (positionIndex < 10) {
          if (positionIndex <= 5) {
            trestle.positions[positionIndex].elements.push(element);
            trestle.positions[positionIndex].length -= element.length;
            element.onTrestle = true;
          } else {
            trestle.positions[positionIndex].elements.splice(5, 0, { element });
            trestle.positions[positionIndex].length -= element.length;
            element.onTrestle = true;
          }
        } else {
          trestles.push({ ...trestle });
          trestle = Object.assign(
            {},
            {
              positions: [
                {
                  elements: [],
                  length: defaultLength,
                },
                {
                  elements: [],
                  length: defaultLength,
                },
                {
                  elements: [],
                  length: defaultLength,
                },
                {
                  elements: [],
                  length: defaultLength,
                },
                {
                  elements: [],
                  length: defaultLength,
                },
                {
                  elements: [],
                  length: defaultLength,
                },
                {
                  elements: [],
                  length: defaultLength,
                },
                {
                  elements: [],
                  length: defaultLength,
                },
                {
                  elements: [],
                  length: defaultLength,
                },
                {
                  elements: [],
                  length: defaultLength,
                },
              ],
            }
          );
        }
      });
    } else {
      // lane.tmpTrestle = this.addEmptySpacesToTrestle(lane.tmpTrestle);

      trestles.push({ ...trestle });
      trestle = Object.assign(
        {},
        {
          positions: [
            {
              elements: [],
              length: defaultLength,
            },
            {
              elements: [],
              length: defaultLength,
            },
            {
              elements: [],
              length: defaultLength,
            },
            {
              elements: [],
              length: defaultLength,
            },
            {
              elements: [],
              length: defaultLength,
            },
            {
              elements: [],
              length: defaultLength,
            },
            {
              elements: [],
              length: defaultLength,
            },
            {
              elements: [],
              length: defaultLength,
            },
            {
              elements: [],
              length: defaultLength,
            },
            {
              elements: [],
              length: defaultLength,
            },
          ],
        }
      );

      module.elements.forEach((element) => {
        if (lane.tmpTrestle.positions.length < 10) {
          if (lane.tmpTrestle.positions.length <= 5) {
            trestle.positions[positionIndex].elements.push(element);
            trestle.positions[positionIndex].length -= element.length;
            element.onTrestle = true;
          } else {
            trestle.positions[positionIndex].elements.splice(5, 0, { element });
            trestle.positions[positionIndex].length -= element.length;
            element.onTrestle = true;
          }
        } else {
          trestles.push({ ...trestle });
          trestle = Object.assign(
            {},
            {
              positions: [
                {
                  elements: [],
                  length: defaultLength,
                },
                {
                  elements: [],
                  length: defaultLength,
                },
                {
                  elements: [],
                  length: defaultLength,
                },
                {
                  elements: [],
                  length: defaultLength,
                },
                {
                  elements: [],
                  length: defaultLength,
                },
                {
                  elements: [],
                  length: defaultLength,
                },
                {
                  elements: [],
                  length: defaultLength,
                },
                {
                  elements: [],
                  length: defaultLength,
                },
                {
                  elements: [],
                  length: defaultLength,
                },
                {
                  elements: [],
                  length: defaultLength,
                },
              ],
            }
          );
        }
      });
    }

    if (lane.tmpTrestle.positions.length === 10) {
      trestles.push({ ...trestle });
      trestle = Object.assign(
        {},
        {
          positions: [
            {
              elements: [],
              length: defaultLength,
            },
            {
              elements: [],
              length: defaultLength,
            },
            {
              elements: [],
              length: defaultLength,
            },
            {
              elements: [],
              length: defaultLength,
            },
            {
              elements: [],
              length: defaultLength,
            },
            {
              elements: [],
              length: defaultLength,
            },
            {
              elements: [],
              length: defaultLength,
            },
            {
              elements: [],
              length: defaultLength,
            },
            {
              elements: [],
              length: defaultLength,
            },
            {
              elements: [],
              length: defaultLength,
            },
          ],
        }
      );
    } else {
      if (index === modules.length - 1) {
        // lane.tmpTrestle = this.addEmptySpacesToTrestle(lane.tmpTrestle);
        trestles.push({ ...trestle });
        trestle = Object.assign(
          {},
          {
            positions: [
              {
                elements: [],
                length: defaultLength,
              },
              {
                elements: [],
                length: defaultLength,
              },
              {
                elements: [],
                length: defaultLength,
              },
              {
                elements: [],
                length: defaultLength,
              },
              {
                elements: [],
                length: defaultLength,
              },
              {
                elements: [],
                length: defaultLength,
              },
              {
                elements: [],
                length: defaultLength,
              },
              {
                elements: [],
                length: defaultLength,
              },
              {
                elements: [],
                length: defaultLength,
              },
              {
                elements: [],
                length: defaultLength,
              },
            ],
          }
        );
      }
    }
  });

  return trestles;
}
