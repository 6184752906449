<template>
  <div
    class="double-trestle-container"
    :style="{
      opacity: selectedTrestle ? (selectedTrestle === trestle ? 1 : 0.3) : 1,
    }"
  >
    <div
      class="pa-1 flex-1"
      style="border-left: dashed 1px #6b6b6b; border-right: dashed 1px #6b6b6b"
    >
      <v-tooltip
        v-for="(position, elementIndex) in trestle.positions.slice(0, 5)"
        :key="`trestle-north-${elementIndex}`"
        bottom
      >
        <template #activator="{ on }">
          <div
            class="flex-1 mb-1 d-flex align-center px-1"
            :style="{
              border: position.isEmpty
                ? 'dotted 1px #6b6b6b'
                : 'solid 1px #6b6b6b',
              background: position.isEmpty
                ? 'transparent'
                : typeColorDictionary[position.element.module_type],
              opacity:
                selectedModule !== null
                  ? selectedModule === position?.element?.module_id
                    ? 1
                    : 0.3
                  : 1,
            }"
            v-on="on"
          >
            <div class="flex-1 d-flex justify-start">
              {{ elementIndex + 1 }}
            </div>
            <div class="flex-1 d-flex justify-end">
              {{
                !position.isEmpty
                  ? position.element.element_type
                    ? position.element.element_type
                    : '-'
                  : ''
              }}
            </div>
          </div>
        </template>
        <span v-if="!position.isEmpty">{{ position.element.element_id }}</span>
      </v-tooltip>
      <div class="my-2 double-trestle-separator"></div>
      <v-tooltip
        v-for="(position, elementIndex) in trestle.positions.slice(5, 10)"
        :key="`trestle-north-${elementIndex}`"
        bottom
      >
        <template #activator="{ on }">
          <div
            class="flex-1 mb-1 d-flex align-center px-1"
            :style="{
              border: position.isEmpty
                ? 'dotted 1px #6b6b6b'
                : 'solid 1px #6b6b6b',
              background: position.isEmpty
                ? 'transparent'
                : typeColorDictionary[position.element.module_type],
              opacity:
                selectedModule !== null
                  ? selectedModule === position?.element?.module_id
                    ? 1
                    : 0.3
                  : 1,
            }"
            v-on="on"
          >
            <div class="flex-1 d-flex justify-start">
              {{ elementIndex + 1 }}
            </div>
            <div class="flex-1 d-flex justify-end">
              {{
                !position.isEmpty
                  ? position.element.element_type
                    ? position.element.element_type
                    : '-'
                  : ''
              }}
            </div>
          </div>
        </template>
        <span v-if="!position.isEmpty">{{ position.element.element_id }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseDhmeDoubleTrestle',
  props: {
    trestle: {
      type: Object,
      required: true,
    },
    selectedTrestle: {
      type: Object,
      required: false,
      default: null,
    },
    typeColorDictionary: {
      type: Object,
      required: true,
    },
    selectedModule: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
.double-trestle-container {
  width: 200px;
  border-bottom: solid 2px #6b6b6b;
  border-top: solid 2px #6b6b6b;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.75);

  .double-trestle-separator {
    background-color: #6b6b6b;
    height: 10px;
    width: 100%;
  }

  &.trestle-large {
    width: 300px;
  }
}
</style>
